import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Privacy = props => (
  <Layout bodyClass="page-privacy">
    <SEO title="Privacy" />
    <div className="container">
      <div className="row">
        <header className="col-12">
          <h1>Privacy Policy</h1>
        </header>
      </div>

      <div className="row">
        <div className="col-12">
          <strong>Brief summary:</strong>
          <p>
            Yes, this site uses cookies. Yep, this site counts how many visitors hit it up daily.
            No, I'm not collecting any other data, nope, I'm not selling it, and nope, this site
            doesn't display any ads or whatever. Here comes the long version from my lawyer:
          </p>
          <h3>1. An overview of data protection</h3>
          <h4>General</h4>
          <p>
            The following gives a simple overview of what happens to your personal information when
            you visit our website. Personal information is any data with which you could be
            personally identified. Detailed information on the subject of data protection can be
            found in our privacy policy found below.
          </p>
          <h4>Data collection on our website</h4>
          <p>
            <strong>Who is responsible for the data collection on this website?</strong><br />
            The data collected on this website are processed by the website operator. The operator's
            contact details can be found in the website's required legal notice.
          </p>
          <p>
            <strong>How do we collect your data?</strong><br />
            Some data are collected when you provide it to us. This could, for example, be data you
            enter on a contact form. Other data are collected automatically by our IT systems when
            you visit the website. These data are primarily technical data such as the browser and
            operating system you are using or when you accessed the page. These data are collected
            automatically as soon as you enter our website.
          </p>
          <p>
            <strong>What do we use your data for?</strong><br />
            Part of the data is collected to ensure the proper functioning of the website. Other
            data can be used to analyze how visitors use the site.
          </p>
          <p>
            <strong>What rights do you have regarding your data?</strong><br />
            You always have the right to request information about your stored data, its origin, its
            recipients, and the purpose of its collection at no charge. You also have the right to
            request that it be corrected, blocked, or deleted. You can contact us at any time using
            the address given in the legal notice if you have further questions about the issue of
            privacy and data protection. You may also, of course, file a complaint with the
            competent regulatory authorities.
          </p>
          <h4>Analytics and third-party tools</h4>
          <p>
            When visiting our website, statistical analyses may be made of your surfing behavior.
            This happens primarily using cookies and analytics. The analysis of your surfing
            behavior is usually anonymous, i.e. we will not be able to identify you from this data.
            You can object to this analysis or prevent it by not using certain tools. Detailed
            information can be found in the following privacy policy. You can object to this
            analysis. We will inform you below about how to exercise your options in this regard.
          </p>
          <h3>2. General information and mandatory information</h3>
          <h4>Data protection</h4>
          <p>
            The operators of this website take the protection of your personal data very seriously.
            We treat your personal data as confidential and in accordance with the statutory data
            protection regulations and this privacy policy. If you use this website, various pieces
            of personal data will be collected. Personal information is any data with which you
            could be personally identified. This privacy policy explains what information we collect
            and what we use it for. It also explains how and for what purpose this happens. Please
            note that data transmitted via the internet (e.g. via email communication) may be
            subject to security breaches. Complete protection of your data from third-party access
            is not possible.
          </p>
          <h4>Notice concerning the party responsible for this website</h4>
          <p>
            The party responsible for processing data on this website is: <br />
            Ines Opifanti<br />
            Dipl. Designerin (Fotodesign) FH<br />
            Von-Bargen-Strasse 46<br />
            22041 Hamburg / Germany<br />
            The responsible party is the natural or legal person who alone or jointly with others decides on the
            purposes and means of processing personal data (names, email addresses, etc.).
          </p>
          <h3>3. Data collection on our website</h3>
          <h4>Cookies</h4>
          <p>
            Some of our web pages use cookies. Cookies do not harm your computer and do not contain
            any viruses. Cookies help make our website more user-friendly, efficient, and secure.
            Cookies are small text files that are stored on your computer and saved by your browser.
            Most of the cookies we use are so-called "session cookies." They are automatically
            deleted after your visit. Other cookies remain in your device's memory until you delete
            them. These cookies make it possible to recognize your browser when you next visit the
            site. You can configure your browser to inform you about the use of cookies so that you
            can decide on a case-by-case basis whether to accept or reject a cookie. Alternatively,
            your browser can be configured to automatically accept cookies under certain conditions
            or to always reject them, or to automatically delete cookies when closing your browser.
            Disabling cookies may limit the functionality of this website. Cookies which are
            necessary to allow electronic communications or to provide certain functions you wish to
            use (such as the shopping cart) are stored pursuant to Art. 6 paragraph 1, letter f of
            DSGVO. The website operator has a legitimate interest in the storage of cookies to
            ensure an optimized service provided free of technical errors. If other cookies (such as
            those used to analyze your surfing behavior) are also stored, they will be treated
            separately in this privacy policy.
          </p>
          <h4>Server log files</h4>
          <p>
            The website provider automatically collects and stores information that your browser
            automatically transmits to us in "server log files". These are:
          </p>
          <ul>
            <li>Browser type and browser version</li>
            <li>Operating system used</li>
            <li>Referrer URL</li>
            <li>Host name of the accessing computer</li>
            <li>Time of the server request</li>
            <li>IP address</li>
          </ul>
          <p>
            These data will not be combined with data from other sources. The basis for data
            processing is Art. 6 (1) (f) DSGVO, which allows the processing of data to fulfill a
            contract or for measures preliminary to a contract.
          </p>
          <h3>4. Analytics and advertising</h3>
          <h4>Matomo (formerly Piwik)</h4>
          <p>
            This website uses the open source web analytics service Matomo. Matomo uses so-called
            "cookies". These are text files that are stored on your computer and that allow an
            analysis of the use of the website by you. For this purpose, the information generated
            by the cookie about the use of this website is stored on our server. The IP address is
            anonymized before it is stored. Matomo cookies remain on your device until you delete
            them. The storage of Matomo cookies is based on Art. 6 (1) (f) DSGVO. The website
            operator has a legitimate interest in analyzing user behavior in order to optimize both
            its website and its advertising. The information generated by the cookies about your use
            of this website will not be disclosed to third parties. You can prevent these cookies
            being stored by selecting the appropriate settings in your browser. However, we wish to
            point out that doing so may mean you will not be able to enjoy the full functionality of
            this website. If you do not agree with the storage and use of your data, you can disable
            this feature here. In this case, an opt-out cookie will be stored in your browser to
            prevent Matomo from storing your usage data. If you delete your cookies, this will mean
            that the opt-out cookie will also be deleted. You will then need to reactivate it when
            you return to our site if you wish your activity not to be tracked.
          </p>
          <iframe
            src="https://stats.damngood.de/index.php?module=CoreAdminHome&action=optOut&language=de"
            style={{
              width: '100%', height: '60px', border: 'none', color: '#fff',
            }}
          />
          <h3>Contact Us</h3>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to
{' '}
            <Link to="/contact">contact us</Link>
.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Privacy;
